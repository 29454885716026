import * as CollapsiblePrimitive from '@radix-ui/react-collapsible'
import { Flex } from '@radix-ui/themes'
import { forwardRef } from 'react'
import { mergeProps, withProps } from '../component-utils'
import styles from './disclosure.module.css'

export {
  Disclosure,
  Disclosure as Root,
  DisclosureTrigger,
  DisclosureTrigger as Trigger,
  DisclosureContent as Content,
}

const Disclosure = forwardRef(
  /**
   * @param {{
   *   orientation?: 'horizontal' | 'vertical'
   * } & Pick<import('@radix-ui/themes').FlexProps, 'gap'> &
   *   import('@radix-ui/react-collapsible').CollapsibleProps} props
   */
  function DisclosureRoot(
    { gap = '2', orientation = 'vertical', ...props },
    ref,
  ) {
    return (
      <Flex
        asChild
        direction={orientation === 'vertical' ? 'column' : 'row'}
        gap={gap}
      >
        <CollapsiblePrimitive.Root
          ref={ref}
          {...mergeProps(props, {
            style: gap && {
              '--trigger-gap': `var(--space-${gap})`,
            },
            className: [styles.root, styles[orientation] ?? styles.vertical],
          })}
        />
      </Flex>
    )
  },
)

const DisclosureTrigger = withProps(CollapsiblePrimitive.Trigger, {
  className: styles.trigger,
})

const DisclosureContent = forwardRef(
  /** @param {import('@radix-ui/react-collapsible').CollapsibleContentProps} props */
  function DisclosureContent({ children, ...props }, ref) {
    return (
      <div className={styles.contentWrapper}>
        <CollapsiblePrimitive.Content
          forceMount
          ref={ref}
          {...mergeProps(props, {
            className: styles.content,
          })}
        >
          {children}
        </CollapsiblePrimitive.Content>
      </div>
    )
  },
)

withProps(CollapsiblePrimitive.Content, {
  className: styles.content,
  forceMount: true,
})
