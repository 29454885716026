import { useRouter } from 'next/router'
import { useState } from 'react'
import { Checkbox, Flex, Text, TextField, Callout } from '@radix-ui/themes'
import { getCsrfToken, signIn as authenticate } from 'next-auth/react'
import Image from 'next/image'
import Head from 'next/head'
import { InfoCircledIcon } from '@radix-ui/react-icons'
import { API, useService } from '../../hooks/use-api'
import { DialogTitle } from '../../components/dialog'
import * as Form from '../../components/form'
import { Link } from '../../components/link'
import { TokenReveal } from '../../components/token-reveal'
import styles from '../auth.module.css'
import { BasicAuth } from '../../components/basic-auth'
import { SemanticColors } from '../../styles/colors'
import { useEnv } from '../../context/env'

export { TemporarySignupPage as SignUpPage }

function TemporarySignupPage(pageProps) {
  const { NEXT_PUBLIC_SIGNUP_PASSWORD_ENABLED } = useEnv()
  const [showSignUp, setShowSignUp] = useState(
    !NEXT_PUBLIC_SIGNUP_PASSWORD_ENABLED,
  )
  const [apiError, setApiError] = useState(null)

  const handleAuthenticate = async ({ password }) => {
    try {
      const res = await fetch('/api/auth/check-signup-password', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ password }),
      })

      const { valid } = await res.json()
      if (!valid) return setApiError('Invalid password')

      setShowSignUp(true)
    } catch {
      setApiError('Something went wrong. Please try again.')
    }
  }

  return showSignUp ? (
    <SignUpPage {...pageProps} />
  ) : (
    <>
      <Flex align="center" direction="column" gap="4">
        <Image
          alt="Under construction sign"
          height={64}
          src="data:image/webp;base64,UklGRkADAABXRUJQVlA4WAoAAAASAAAACQEACQEAQU5JTQYAAAAAAAAAAABBTk1GgAEAAAAAAAAAAAkBAAkBAFAAAABWUDhMaAEAAC8JQUIQFzDd8x/zH+AAq7btuI2SthDMxxA63UwQxEcQOkg4n+57Tx3vX0T/GbltG4m5D/ZR+4WSmx1mtpIctYDMg45egTbMVAAHDT0CGGRuAAS0A+iDzwnY1APgbfgD4EERi5PhDA2IItYno8JM4wFRxOpkgMwbD4gi1iQjxAuIIvLJCPIDoojc/iuHB0QRmWRU0AksBwYPiCLiyajmSInjeQFRRDwZN/TE32X4AVFENBk3/pPxBQKiCJqMkD58wYBoAnBOrwIh2YAo5W2coChzXor4Fn9L2djlQAxgFgd9wYpCD2Th7pkP18zpZdf+enC22Jw9r/lk0DK7dGnYHVUQO5lQMoo25oAkofnJ0AYNiGd+9ci88WSIww3IbTK/gSfw6XMyxBEKyClnSoZWyg4s2X8phe7NIngypOLtzSLmZEglsjfzTcnQxpqAhJKhjWUB8ZMhjpUB4ckQUSmwY5Oho7JbWyliKUFOTUZ6AAAAHAAAKgAAfQAAYQAACgAAAFZQOExiAAAAL31AGBAXMN3zH/Mf4OA2kiRFqmPQ15814tiEs+KceJ2p7XyUOl6L6P8E9IEfG7BT6w2xvyi15o+819B7A3f1wRuQtPSJM2xq6XpnYK+UPjgDdxH7p8EU0Pqs7wtTXvpDdABBTk1GegAAABwAADEAAIQAAFMAAAoAAABWUDhMYQAAAC+EwBQQFzDd8x/zH+DAJrJtJ5cc+m8CFUiI1wQqvgk8MM/mT1DdNqL/E8DHZvwshsPvYpCdUvmvKbWRtCXnIp0yIckutjHaaZNkdiWQ3VqlV5W8hQA2lWYAZjbjcy2SXg8AQU5NRogAAAAcAAAjAACSAABhAAAKAAAAVlA4TG8AAAAvkkAYEBcw3fMf8x/g4Ca2bVdZ5NB/E6j4EkjHz5fyS+bpJL3qQhfR/wnQ+5xE5+pkqNEPdMzmN3BP5e6BGk3t2GHMBvXJoHu5Mjjn5YIxT4fqAADtZG5LmNsSzkkAQIHAoz1d6lggTO3N5T8PKBMA"
          width={64}
        />
        <Text>Welcome!</Text>
      </Flex>
      <Flex direction="column" gap="3">
        <Text>
          You have been invited to sign up for a pre-release Synqly account. To
          proceed, enter the password we gave you and please mind the
          construction.
        </Text>
        {!!apiError && (
          <Callout.Root color={SemanticColors.ERROR}>
            <Callout.Icon>
              <InfoCircledIcon />
            </Callout.Icon>
            <Callout.Text>{apiError}</Callout.Text>
          </Callout.Root>
        )}
        <BasicAuth onAuthenticate={handleAuthenticate} />
      </Flex>
    </>
  )
}

function SignUpPage({ validation, redirectUrl }) {
  const router = useRouter()
  const [createSuccess, setCreateSuccess] = useState(null)
  const { callPost: callSignup } = useService(API('/auth/signup'))

  return (
    <>
      <Head>
        <title>Sign up</title>
      </Head>

      <DialogTitle className={styles.heading}>Create account</DialogTitle>
      {!createSuccess && (
        <Form.Root action={createOrganization}>
          <Form.Field name="organization" required>
            <Form.Label>
              <Text>Organization</Text>
            </Form.Label>
            <Form.Control asChild>
              <TextField.Root
                autoComplete="off"
                placeholder="Acme Corporation"
              />
            </Form.Control>
          </Form.Field>

          <Form.Field name="fullname" required>
            <Form.Label>
              <Text>Full name</Text>
            </Form.Label>
            <Form.Control asChild>
              <TextField.Root placeholder="Alice Smith" required />
            </Form.Control>
          </Form.Field>

          <Form.Field name="email" required>
            <Form.Label>
              <Text>E-mail</Text>
            </Form.Label>
            {...validation.email.messages}
            <Form.Control asChild>
              <TextField.Root
                {...validation.email.rules}
                autoComplete="username"
                placeholder="alice@company.com"
                type="email"
              />
            </Form.Control>
          </Form.Field>

          <Form.Field name="password" required>
            <Form.Label>
              <Text>Password</Text>
            </Form.Label>
            {...validation.password.messages}
            <Form.Control asChild>
              <TextField.Root
                {...validation.password.rules}
                autoComplete="new-password"
                placeholder="••••••••"
                type="password"
              />
            </Form.Control>
          </Form.Field>

          <Form.Field name="eula" required>
            <Form.Control asChild>
              <Checkbox value="agree" />
            </Form.Control>
            <Form.Label>
              I agree to the{' '}
              <Link
                href="/static/legal/data-privacy-agreement-20230928.pdf"
                target="_blank"
              >
                Data Privacy Agreement
              </Link>
              {' and '}
              <Link
                href="/static/legal/eval-agreement-20231113.pdf"
                target="_blank"
              >
                Software Evaluation Agreement
              </Link>
            </Form.Label>
          </Form.Field>

          <Form.Submit mt="5">Create account</Form.Submit>

          <Text align="center" mt="2" size="1">
            Already have an account? <Link href="/auth/signin">Sign in</Link>
          </Text>
        </Form.Root>
      )}

      {!!createSuccess && (
        <TokenReveal
          onConfirm={async () => {
            const { signInParams } = createSuccess
            const signIn = await authenticate('credentials', signInParams)
            await router.replace(redirectUrl ?? signIn.url ?? '/')
          }}
          title="Welcome to Synqly!"
          token={createSuccess.token}
        />
      )}
    </>
  )

  async function createOrganization(formData) {
    const body = {
      ...Object.fromEntries(formData),
      csrfToken: await getCsrfToken(),
    }
    const signUp = await callSignup({ body })

    if (signUp.error) {
      throw signUp.error
    }

    const { organization, token } = signUp.data.result

    setCreateSuccess({
      token,
      signInParams: {
        redirect: false,
        callbackUrl: '/',
        organization: organization.id,
        username: body.email,
        password: body.password,
      },
    })
  }
}
