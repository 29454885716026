import { ExternalLinkIcon } from '@radix-ui/react-icons'
import { Link as LinkPrimitive, Slot } from '@radix-ui/themes'
import Route from 'next/link'
import { forwardRef } from 'react'
import { mergeProps, withProps } from '../component-utils'
import { createContext } from '../context/context-utils.jsx'
import styles from './link.module.css'

export { Link, LinkContext, constructHref }

const [LinkContext, useLinkContext] = createContext('Link', {
  defaultValue: {
    basePath: '/',
    origin: process.env.NEXT_PUBLIC_APP_URL,
  },
})

const Link = forwardRef(
  /**
   * @param {{
   *   href: Href
   * } & Omit<
   *   import('react').ComponentPropsWithoutRef<typeof LinkPrimitive> &
   *     import('next/link').LinkProps,
   *   'href'
   * >} props
   */
  function Link(props, ref) {
    const { basePath, origin } = useLinkContext()
    const {
      asChild,
      href,
      target = resolveTarget(href, origin),
      children = href?.toString(),
      ...remainingProps
    } = props

    const externalTarget = target === '_blank'
    const Component = asChild ? Slot : ThemedLink

    const prefixedHref = externalTarget ? href : constructHref(href, basePath)

    return (
      <Component
        {...mergeProps(remainingProps, {
          ref,
          target,
        })}
      >
        <Route href={prefixedHref}>
          {children}
          {!!externalTarget && (
            <ExternalLinkIcon className={styles.externalIcon} />
          )}
        </Route>
      </Component>
    )
  },
)

const ThemedLink = withProps(LinkPrimitive, {
  asChild: true,
})

/** @typedef {string | URL | { hostname?: string; host?: string }} Href */

/**
 * @param {Href} url
 * @param {string | URL} origin
 */
function resolveTarget(url, origin) {
  if (!url) return

  let resolvedOrigin
  if (typeof url === 'string' || url instanceof URL) {
    try {
      resolvedOrigin = new URL(url, origin).origin
    } catch (error) {
      console.warn(error.message, url)
    }
  } else {
    resolvedOrigin = url.hostname || url.host?.split(':')[0] || origin
  }

  if (resolvedOrigin !== origin) {
    return '_blank'
  }
}

/**
 * Constructs a valid href using the given basePath.
 *
 * @param {string | Partial<URL>} href - The original href provided to the Link.
 * @param {string} basePath - The base path to prefix the href with, defaults to
 *   `/`.
 * @returns {string | Partial<URL>} - The constructed href.
 */
function constructHref(href, basePath) {
  const pathname = typeof href === 'string' ? href : href.pathname
  const formattedPathname = `/${basePath}/${pathname}`.replace(/\/\/+/g, '/')
  if (typeof href === 'string') {
    return formattedPathname
  }

  const formattedHref = {}
  for (const key in href) {
    // Loop through enumerable keys in case href is a URL, since object
    // spreading doesn't work in that case.
    formattedHref[key] = href[key]
  }

  formattedHref.pathname = formattedPathname
  return formattedHref
}
