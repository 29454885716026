import React, { Children, isValidElement, cloneElement, Fragment } from 'react'
import { isFragment } from 'react-is'
import { clsx } from 'clsx'
import { valueOf } from '@synqly/fun'

export { mergeProps, withProps, toFlatArray, attr }

/**
 * Checks that the given value is truthy, otherwise returns the fallback.
 *
 * @param {any} value
 * @param {any} [fallback]
 */
function attr(value, fallback) {
  return value ? value : fallback
}

/**
 * Shallowly merges multiple props into one object. If multiple objects have the
 * same props, later objects will take precedence, except for the `className`
 * and `style` props, which will be a union of all values.
 */
function mergeProps(...all) {
  // Merged is a const, but gets mutated by Object.assign
  const merged = {}

  for (const props of all) {
    Object.assign(merged, props, {
      style: { ...merged.style, ...props?.style },
      className: clsx(merged.className, props?.className),
    })
  }

  return merged
}

/**
 * Wraps the given component in a new render function that will merge the given
 * props, or prop producing function, with whatever props are given at render
 * time. This can be used to easily augment existing render functions with
 * different props.
 *
 * Props are merged using `mergeProps`.
 *
 * @template {import('react').ElementType} T
 * @template {Omit<import('react').ComponentProps<T>, 'className'> & {
 *   className?: string | import('clsx').ClassValue
 * }} P
 *
 * @param {T} Component
 * @param {Partial<P> | ((props: P) => Partial<P>)} extraProps
 * @param {string} [displayName]
 * @returns {T}
 * @see mergeProps
 */
function withProps(Component, extraProps, displayName) {
  const ComponentWithProps = React.forwardRef((/** @type P */ props, ref) => {
    const finalProps = mergeProps(valueOf(extraProps, props), props)
    return <Component ref={ref} {...finalProps} />
  })

  if (displayName) {
    ComponentWithProps.displayName = displayName
  }

  return ComponentWithProps
}

/**
 * Same as Children.toArray, but also flattens fragments.
 *
 * @param {import('react').ReactNode | import('react').ReactNode[]} children
 */
function toFlatArray(children) {
  return flattenChildren(children)
}

/**
 * @param {import('react').ReactNode | import('react').ReactNode[]} children
 * @param {(string | number)[]} keys
 */
function flattenChildren(children, keys = []) {
  return Children.toArray(children).flatMap((node, nodeIndex) => {
    if (isFragment(node)) {
      return flattenChildren(node.props.children, [
        ...keys,
        node.key || nodeIndex,
      ])
    } else if (isValidElement(node)) {
      return [
        cloneElement(node, {
          key: [...keys, node.key].join(''),
        }),
      ]
    }

    const key = [...keys, nodeIndex].join('')

    return [<Fragment key={key}>{node}</Fragment>]
  })
}
