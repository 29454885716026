import { getSession } from 'next-auth/react'
import { useEffect, useState } from 'react'
import { Box } from '@radix-ui/themes'
import * as Dialog from '../components/dialog'
import { Logo } from '../components/logo'
import { API, withServerSideFallback } from '../hooks/use-api'
import { ValidationMessage } from '../components/form'
import { SignUpPage } from './auth/signup'
import { SignInPage } from './auth/signin'
import { ResetPage } from './auth/reset'
import styles from './auth.module.css'
import { ErrorPage } from './_error'

export { SynqlyAuth, PasswordValidation }

/**
 * @param {{
 *   slugParam: string
 *   canSignIn: boolean
 *   canSignUp: boolean
 *   canVerify: boolean
 *   canReset: boolean
 *   resolveOrganizationId?: (id: string) => string | Promise<string>
 *   redirectUrl: string
 * }} config
 */
function SynqlyAuth({
  slugParam = 'synqly-auth',
  canSignIn = true,
  canSignUp = false,
  canVerify = true,
  canReset = true,
  redirectUrl = '/',
}) {
  AuthPage.Layout = AuthModal

  const ActionPages = {
    default: () => null,
  }

  ActionPages[Actions.SignIn] = canSignIn && SignInPage
  ActionPages[Actions.SignUp] = canSignUp && SignUpPage
  ActionPages[Actions.Reset] = (canReset || canVerify) && ResetPage

  return { AuthPage, getServerSideProps }

  /** @type {import('next').GetServerSideProps} */
  async function getServerSideProps(context) {
    const { [slugParam]: action, ...query } = context.query
    const [route] = action

    if (!ActionPages[route]) {
      return notFound()
    }

    const allowedOrgs = process.env.NEXT_PUBLIC_ALLOWED_ORGS?.split(',')
    const blockedOrgs = process.env.NEXT_PUBLIC_BLOCKED_ORGS?.split(',')

    const organizationId =
      allowedOrgs?.length === 1 ? allowedOrgs[0] : query.organizationId

    if (
      (allowedOrgs && !allowedOrgs.includes(organizationId)) ||
      (blockedOrgs && blockedOrgs.includes(organizationId))
    ) {
      return notFound()
    }

    const session = await getSession({ broadcast: false })

    if (session) {
      const redirectUrl =
        typeof context.params.redirectUrl === 'string'
          ? context.params.redirectUrl
          : context.params.redirectUrl[0]

      return {
        redirect: {
          destination: redirectUrl ?? '/',
          permanent: false,
        },
      }
    }

    const organization = await getOrganization(organizationId, context)

    const url = new URL(
      context.resolvedUrl,
      process.env.APP_URL ?? process.env.NEXT_PUBLIC_APP_URL,
    )

    const mountPath = url.pathname.split('/').slice(0, -action.length).join('/')

    return {
      props: {
        organization,
        query,
        action: action[0],
        params: action.slice(1),
        routes: {
          signIn: canSignIn && `${mountPath}/${Actions.SignIn}`,
          signUp: canSignUp && `${mountPath}/${Actions.SignUp}`,
          reset: canReset && `${mountPath}/${Actions.Reset}`,
        },
      },
    }

    function notFound() {
      context.res.statusCode = 404
      return {
        props: {
          error: { statusCode: 404 },
        },
      }
    }
  }

  function AuthPage({ action, organization, query, params, routes, error }) {
    if (error) {
      return <ErrorPage {...error} />
    }

    const Page = ActionPages[action] ?? ActionPages.default

    return (
      <Page
        organization={organization}
        params={params}
        query={query}
        redirectUrl={redirectUrl}
        routes={routes}
        validation={{
          email: EmailValidation,
          password: PasswordValidation,
        }}
      />
    )
  }
}

async function getOrganization(id, context) {
  if (!id) {
    return null
  }

  if (process.env.APP_ENV === 'embedded') {
    return { name: id }
  }

  const OrganizationsAPI = API(`/v1/organizations/{organizationId}`, {
    token: process.env.MANAGEMENT_TOKEN,
    props: {
      organizationId: id,
    },
  })

  const {
    props: { fallbackData },
  } = await withServerSideFallback(OrganizationsAPI)(context)

  return fallbackData[OrganizationsAPI]?.result ?? null
}

const EmailValidation = {
  messages: [
    <ValidationMessage key="typeMismatch" match="typeMismatch">
      must be a valid email address
    </ValidationMessage>,
  ],
}

const PasswordValidation = {
  rules: {
    /** @see https://github.com/Synqly/lepton/blob/7e549bc6194bf08541fd942cfadd17b02b43ecf0/utils/password/password.go#L13-L16 */
    pattern: '.{8,72}',
  },
  messages: [
    <ValidationMessage key="patternMismatch" match="patternMismatch">
      must be 8-72 characters
    </ValidationMessage>,
  ],
}

const Actions = {
  SignIn: 'signin',
  SignUp: 'signup',
  Reset: 'reset',
}

/**
 * @param {{
 *   children?: import('react').ReactNode
 * }} props
 */
function AuthModal({ children }) {
  const [open, setOpen] = useState(false)
  useEffect(() => setOpen(true), [])

  return (
    <Dialog.Root open={open}>
      <Dialog.Content className={styles.modalContent} size="4">
        <Box asChild mb="2">
          <Logo className={styles.logo} />
        </Box>
        {children}
      </Dialog.Content>
    </Dialog.Root>
  )
}
