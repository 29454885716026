import * as FormPrimitive from '@radix-ui/react-form'
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react'
import { Text, Callout, Slot, Box, IconButton, Flex } from '@radix-ui/themes'
import { InfoCircledIcon, Cross2Icon } from '@radix-ui/react-icons'
import clsx from 'clsx'
import { mergeProps } from '../component-utils.jsx'
import { createContext } from '../context/context-utils.jsx'
import { SemanticColors } from '../styles/colors.js'
import styles from './form.module.css'
import * as Disclosure from './disclosure.jsx'
import { Button } from './button.jsx'

export {
  Root,
  Submit,
  FieldSet,
  FieldSetLegend,
  Field,
  Label,
  Control,
  Description,
  ValidityState,
  ValidationMessage,
  MessageSeverity,
}

const [FormStateContext, useFormState] = createContext('FormState', {
  defaultValue: {
    isSubmitting: false,
  },
})

const [FieldPropsContext, useFieldProps] = createContext('FieldProps', {
  defaultValue: {
    hidden: false,
    required: false,
  },
})

const [FieldValidityContext] = createContext('FieldValidity', {
  defaultValue: {
    validity: /** @type {ValidityState} */ (undefined),
  },
})

const [FieldStateContext, useFieldState] = createContext('FormFieldDataState', {
  defaultValue: {
    'data-valid': /** @type {true | false | undefined} */ (undefined),
    'data-invalid': /** @type {true | false | undefined} */ (undefined),
    'data-required': false,
  },
})

const MessageSeverity = /** @type {const} */ ({
  INFO: 'INFO',
  ERROR: 'ERROR',
  DANGER: 'DANGER',
  SUCCESS: 'SUCCESS',
  WARNING: 'WARNING',
  IGNORED: 'IGNORED',
})

const Root = forwardRef(
  /** @typedef {(formData: FormData) => void | Promise<void>} FormAction */

  /**
   * @param {{
   *   action?: string | FormAction
   *   onActionError?: (error: Error) =>
   *     | {
   *         message: string
   *         severity?: (typeof MessageSeverity)[keyof MessageSeverity]
   *       }
   *     | { severity: typeof MessageSeverity.IGNORED }
   *     | void
   *   initialMessage?:
   *     | string
   *     | {
   *         message: String
   *         severity?: (typeof MessageSeverity)[keyof MessageSeverity]
   *       }
   *   defaultSeverity?: (typeof MessageSeverity)[keyof MessageSeverity]
   * } & Omit<
   *   import('react').ComponentPropsWithoutRef<typeof FormPrimitive.Root>,
   *   'action'
   * >} props
   */
  function Form(
    {
      action,
      onSubmit,
      onActionError,
      children,
      initialMessage,
      defaultSeverity = MessageSeverity.ERROR,
      ...props
    },
    ref,
  ) {
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [formMessage, setFormMessage] = useState(
      initialMessage && {
        severity: initialMessage.severity ?? defaultSeverity,
        message: initialMessage.message ?? initialMessage,
      },
    )

    const [showMessage, setShowMessage] = useState(
      !isSubmitting && !!formMessage,
    )

    useEffect(() => {
      setShowMessage(!isSubmitting && !!formMessage)
    }, [isSubmitting, formMessage])

    const handleSubmit = useCallback(
      /**
       * @param {import('react').SyntheticEvent<
       *   HTMLFormElement,
       *   SubmitEvent
       * >} event
       */
      async (event) => {
        const {
          currentTarget,
          nativeEvent: { submitter },
        } = event

        setIsSubmitting(true)
        onSubmit?.(event)

        if (typeof action === 'function') {
          event.preventDefault()
          const formData = new FormData(currentTarget, submitter)

          try {
            await action?.(formData)
            setFormMessage(null)
          } catch (error) {
            const custom = onActionError?.(error)

            if (custom?.severity !== MessageSeverity.IGNORED) {
              setFormMessage({
                message: custom?.message ?? error.message ?? error,
                severity: custom?.severity ?? error.severity ?? defaultSeverity,
              })
            }
          }
        }

        setIsSubmitting(false)
      },
      [onSubmit, action, onActionError, defaultSeverity],
    )

    return (
      <FormStateContext.Provider value={{ isSubmitting }}>
        <FormPrimitive.Root
          onInvalid={() => setFormMessage(null)}
          onSubmit={handleSubmit}
          ref={ref}
          {...mergeProps(props, {
            className: styles.root,
          })}
        >
          <StickyBox ref={ref}>
            <Disclosure.Root
              className={styles.formMessageDisclosureRoot}
              open={showMessage}
            >
              <Disclosure.Content forceMount>
                {!!formMessage && (
                  <FormMessage
                    message={formMessage.message}
                    onClose={() => setShowMessage(false)}
                    severity={formMessage.severity}
                  />
                )}
              </Disclosure.Content>
            </Disclosure.Root>
          </StickyBox>
          <Flex direction="column" flexGrow="1" gap="4">
            {children}
          </Flex>
        </FormPrimitive.Root>
      </FormStateContext.Provider>
    )
  },
)

const StickyBox = forwardRef(
  /** @param {import('react').PropsWithChildren} props */
  function StickyBox({ children }, ref) {
    const [isSticky, setIsSticky] = useState(false)
    const boxRef = useRef()

    useEffect(() => {
      const observer =
        ref?.current &&
        new IntersectionObserver(
          ([{ intersectionRatio }]) => {
            setIsSticky(intersectionRatio === 1)
          },
          {
            root: ref?.current,
            rootMargin: '-1px 0px 0px 0px',
            threshold: [1],
          },
        )

      observer?.observe(boxRef?.current)

      return () => observer?.disconnect()
    }, [boxRef, ref, setIsSticky])

    return (
      <Box
        className={clsx(styles.stickyBox, isSticky && styles.stickyBoxFloating)}
        ref={boxRef}
      >
        {children}
      </Box>
    )
  },
)

/**
 * @param {{
 *   onClose: () => void
 *   message: string
 *   severity: keyof typeof MessageSeverity
 * }} props
 */
function FormMessage({
  onClose,
  message = 'Unexpected error',
  severity = MessageSeverity.ERROR,
}) {
  return (
    <Callout.Root
      className={styles.formMessage}
      color={SemanticColors[severity] ?? SemanticColors[MessageSeverity.ERROR]}
    >
      <Callout.Icon className={styles.formMessageIcon}>
        <InfoCircledIcon />
      </Callout.Icon>
      <Callout.Text className={styles.formMessageText}>{message}</Callout.Text>
      {!!onClose && (
        <Callout.Text>
          <IconButton
            className={styles.formMessageDismissButton}
            onClick={() => onClose()}
            title="Dismiss"
            type="button"
            variant="ghost"
          >
            <Cross2Icon />
          </IconButton>
        </Callout.Text>
      )}
    </Callout.Root>
  )
}

const FieldSet = forwardRef(
  /**
   * @param {{
   *   children: import('react').ReactNode
   * } & import('react').ComponentPropsWithoutRef<'fieldset'>} props
   */
  function FieldSet({ children, ...props }, ref) {
    return (
      <fieldset
        ref={ref}
        {...mergeProps(props, {
          className: styles.fieldset,
        })}
      >
        {children}
      </fieldset>
    )
  },
)

const FieldSetLegend = forwardRef(
  /**
   * @param {{
   *   children: import('react').ReactNode
   * } & import('react').ComponentPropsWithoutRef<'legend'>} props
   */
  function FieldSetLegend({ children, ...props }, ref) {
    return (
      <legend
        ref={ref}
        {...mergeProps(props, {
          className: styles.fieldsetLegend,
        })}
      >
        {children}
      </legend>
    )
  },
)

const Field = forwardRef(
  /**
   * @param {{
   *   required?: true | false
   * } & import('react').ComponentPropsWithoutRef<typeof FormPrimitive.Field>} props
   */
  function Field({ name, hidden, required, ...props }, forwardedRef) {
    const [validity, setValidity] = useState(
      /** @type {ValidityState} */ (undefined),
    )

    const fieldState = {
      'data-valid': validity ? validity.valid : undefined,
      'data-invalid': validity ? !validity.valid : undefined,
      'data-required': !!required,
    }

    const ref = useRef(forwardedRef)

    return (
      <FieldPropsContext.Provider value={{ hidden, required }}>
        <FieldValidityContext.Provider value={{ validity }}>
          <FieldStateContext.Provider value={fieldState}>
            <FormPrimitive.Field
              {...fieldState}
              hidden={hidden}
              name={name}
              onChange={() => {
                setValidity(undefined)
              }}
              onInvalid={(event) => {
                if (event.target.closest(`.${styles.field}`) === ref.current) {
                  setValidity(event.target.validity)
                }
              }}
              ref={ref}
              {...mergeProps(props, { className: styles.field })}
            />
          </FieldStateContext.Provider>
        </FieldValidityContext.Provider>
      </FieldPropsContext.Provider>
    )
  },
)

const Label = forwardRef(
  /**
   * @param {import('react').ComponentPropsWithoutRef<
   *   typeof FormPrimitive.Label
   * >} props
   */
  function Label(props, ref) {
    const state = useFieldState()

    return (
      <FormPrimitive.Label
        {...mergeProps(props, state, {
          className: styles.label,
        })}
        ref={ref}
      />
    )
  },
)

const Control = forwardRef(
  /**
   * @param {{
   *   variant?: 'normal' | 'inline' | 'inline-start' | 'inline-end' | 'hidden'
   * } & Omit<
   *   import('react').ComponentPropsWithoutRef<typeof FormPrimitive.Control>,
   *   'hidden' | 'required'
   * >} props
   */
  function Control({ children, ...props }, ref) {
    const { hidden, required } = useFieldProps()
    const state = useFieldState()

    return (
      <FormPrimitive.Control
        asChild={!!children}
        hidden={hidden}
        ref={ref}
        required={required}
        {...mergeProps(
          {
            variant: 'soft',
            className: styles.control,
          },
          props,
          state,
        )}
      >
        {children}
      </FormPrimitive.Control>
    )
  },
)

const ValidationMessage = forwardRef(
  /** @param {import('@radix-ui/react-form').FormMessageProps} props */ function ValidationMessage(
    props,
    ref,
  ) {
    return (
      <FormPrimitive.Message
        ref={ref}
        {...mergeProps(props, { className: styles.message })}
      />
    )
  },
)

const Description = forwardRef(
  /** @param {import('react').ComponentPropsWithoutRef<typeof Text>} props */
  function Description({ children, ...props }, ref) {
    return (
      <Text
        as={typeof children === 'string' ? 'p' : 'div'}
        ref={ref}
        size="1"
        {...mergeProps(props, {
          className: styles.description,
        })}
      >
        {children}
      </Text>
    )
  },
)

const Submit = forwardRef(
  /**
   * @param {{
   *   disabledOnSubmit?: boolean
   * } & import('react').ComponentPropsWithoutRef<typeof FormPrimitive.Submit>} props
   */
  function Submit(
    { disabledOnSubmit = true, disabled, asChild, children, ...props },
    ref,
  ) {
    const { isSubmitting } = useFormState()

    const SubmitButton = asChild ? Slot : Button

    return (
      <FormPrimitive.Submit
        asChild
        disabled={disabledOnSubmit ? isSubmitting : disabled}
        ref={ref}
        type="submit"
        {...mergeProps(props, {
          loading: isSubmitting,
          className: styles.submit,
        })}
      >
        <SubmitButton>{children}</SubmitButton>
      </FormPrimitive.Submit>
    )
  },
)

const ValidityState = FormPrimitive.ValidityState
