import { getMatchingGrayColor } from '@radix-ui/themes/helpers'
import { accentColors, grayColors } from '@radix-ui/themes/props'

export { AccentColors, GrayColors, SemanticColors, getMatchingGrayColor }

/**
 * @template {readonly string[]} T
 * @param {T} values
 * @returns {{
 *   readonly [K in Uppercase<T[number]>]: Lowercase<K>
 * }}
 */
function toEnum(values) {
  // @ts-ignore
  return Object.fromEntries(values.map((value) => [value.toUpperCase(), value]))
}

const AccentColors = toEnum(accentColors)
const GrayColors = toEnum(grayColors)

const SemanticColors = /** @type {const} */ ({
  INFO: 'accent',
  ERROR: AccentColors.RED,
  DANGER: AccentColors.RED,
  SUCCESS: AccentColors.GREEN,
  WARNING: AccentColors.ORANGE,
})
